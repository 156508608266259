import React from "react";
import { Link } from "react-router-dom/";
import { LinkSpan } from "./styles";
import { PersonTitle } from "../../pages/People/styles";
import IconMaterial from "../../pages/People/IconMaterial";
import { Row, Col } from "react-bootstrap";

const PersonGeneralLink = ({ name, people, contents, makeLink }) => {
  if (name in people) {
    let nameText = people[name].name.trim();
    if(nameText === "Cody Dunne"){
      return makeLink ? <Link style={{ fontWeight: "bold"}} to="/">{contents}</Link> : <LinkSpan style={{ fontWeight: "bold"}}>{contents}</LinkSpan>;
    } else{
      let pageLink = process.env.REACT_APP_KVSM_SITE + "/people/" + nameText.replaceAll(" ", "-") + "/";
      return makeLink ? <a href={pageLink}>{contents}</a> : <LinkSpan>{contents}</LinkSpan>;
    }    
  } else {
    return contents;
  }
}

const PersonLink = ({ name, people }) => {
  return <PersonGeneralLink name={name} people={people} contents={name} makeLink={true} />;
};

const PersonLinks = ({ people, personId }) => {
  let person = Object.entries(people).find(
    (nameInfo) => nameInfo[1].name.trim().replaceAll(" ", "-") === personId
  );
  if (person) {
    person = person[1];
  } else {
    return "PERSON NOT FOUND!!!";
  }

  let listMaterials = Object.entries(person.materials);
  listMaterials = listMaterials.filter((pair) => pair[1]);
  listMaterials[0] = ["Group Page", process.env.REACT_APP_KVSM_SITE]// Replace Homepage with Group Page
  let cvIndex = listMaterials.findIndex(pair => pair[0] === "CV");
  listMaterials[cvIndex][1] = "/dunne_cv.pdf";

  return (
    <>
      <Row>
        <Col>
          <PersonTitle style={{ fontSize: "18px" }}>{"Assistant Professor, Khoury College of Computer Sciences, Northeastern University"}</PersonTitle>
          {listMaterials.map((pair, index) => (
            <Row key={index}>
              <IconMaterial key={index} material={pair} />
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
};


export default PersonLink;
export {
  PersonLink,
  PersonGeneralLink,
  PersonLinks
}
