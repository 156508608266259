import React from "react";
import {
  CarouselImg,
  StyledCarousel,
  CenteredRow,
  UpdateDate,
  UpdateText,
  CarouselText,
  StyledBigIconLink,
  IconStack
} from "./styles";
import { StyledTitle } from "../../styles";
import { FaTwitter, FaYoutube } from "react-icons/fa";
import { Carousel, Row, Col } from "react-bootstrap";
import Citation from "../../components/Citation";
import { Link } from "react-router-dom";
import "./style.css";
import { PersonLinks } from "../../components/PersonLink/PersonLink";


const Carousels = ({ carousels }) => {
  // Randomize the order of the carousel items.
  // https://en.wikipedia.org/wiki/Fisher–Yates_shuffle
  const shuffledCarousels = carousels.slice();
  for (let i = shuffledCarousels.length - 1; i >= 1; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = shuffledCarousels[i];
    shuffledCarousels[i] = shuffledCarousels[j];
    shuffledCarousels[j] = temp;
  }

  return (
    <StyledCarousel interval={null} indicators={true} controls={true}>
      {shuffledCarousels.map((carousel, index) => (
        <Carousel.Item key={index}>
          <a href={process.env.REACT_APP_KVSM_SITE + carousel.link}>
            <CarouselImg
              style={{ height: "400px" }}
              src={process.env.PUBLIC_URL + "/kvsm" + carousel.image}
              alt={"Slide " + index + ": " + carousel.title}
            />
          </a>
          <Carousel.Caption>
            <CarouselText>{carousel.title}</CarouselText>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </StyledCarousel>
  );
};

const About = () => {
  return (
    <div className="article-content">
      <StyledTitle>Bio</StyledTitle>
      <p>
        I am an Assistant Professor in the <a href={process.env.REACT_APP_KVSM_SITE}>Khoury Vis Lab</a>, part of the <a href="https://www.khoury.northeastern.edu/">Khoury College of Computer Sciences</a> at <a href="https://www.northeastern.edu/">Northeastern University</a>.
        I create <strong>readable visualizations of network relationships</strong> that help users understand complex data.
        I mainly address visualizing <strong>layered networks</strong>, in which the nodes in the network are constrained to lie on parallel layers.
        My research leverages the algorithmic aspects of <strong>computer science</strong> to build and assess new visualization techniques and tools, as well as <strong>human-computer interaction (HCI)</strong> methodologies to understand the perceptual abilities, goals, tasks, and processes of uses and validate visualization designs.
        This work has led to my contributing <strong>new HCI research methods</strong> and <strong>novel visualization and HCI theory</strong> that can guide future researchers and practitioners.
        I often use <strong>applied research</strong> across various domains to help generate novel basic research contributions.
        These cross-disciplinary collaborations provide me with new questions, data sets, case studies, and invested users whose work benefits from our partnerships.
      </p>
      <hr />
      <p>
        <strong>Areas of investigation:</strong> Data Visualization + ...
      </p>
      <div style={{ width: "100%" }}>

        <div style={{ width: "50%", float: "left" }}>
          <ul>
            <li>Graph and network data</li>
            <li>Multidimensional data</li>
            <li>Geospatial data</li>
            <li>Temporal event sequences</li>
            <li>Evaluation methodologies</li>
            <li>Layout algorithms</li>
          </ul>
        </div>
        <div style={{ width: "50%", float: "right" }}>
          <ul>
            <li>Exploratory data analysis</li>
            <li>User interface design</li>
            <li>Interaction design</li>
            <li>Perception and cognition</li>
            <li>Analytic provenance</li>
            <li>Virtual and extended reality (VR & XR)</li>
          </ul>
        </div>
      </div>
      <p>
        <strong>Specific domains of interest:</strong>
      </p>
      <ul>
        <li>
          <strong>Healthcare diagnostic</strong>{" "}
          <strong>and treatment decision support</strong>, including diabetes & neurology
        </li>
        <li>
          <strong>Cybersecurity</strong>, including unmanned autonomous system
          analysis and improving network resilience
        </li>
        <li>
          <strong>Computer Science and Engineering</strong>, including work in databases,
          programming languages, natural language processing, systems, and hardware simulation
        </li>
        <li>
          <strong>Digital humanities</strong>, including networks of concepts in
          humanities texts as well as text and timeline visualizations
        </li>
        <li>
          <strong>Epidemiology</strong>, including the spread of infectious
          disease
        </li>
      </ul>
    </div>
  );
};

const Updates = ({ updates }) => {
  let recUpdates = updates.slice(0, 8);
  return (
    <>
      <StyledTitle>Recent News</StyledTitle>
      {recUpdates.map((update, index) => (
        <Row key={index}>
          <div>
            <UpdateDate>{update.date}</UpdateDate>:{" "}
            <UpdateText dangerouslySetInnerHTML={{ __html: update.html }} />
          </div>
        </Row>
      ))}
    </>
  );
};

const RecentPublications = ({ papers, people, count }) => {
  // Filter to just Cody's papers
  papers = papers.filter((paper) => paper.authors.includes("Cody Dunne"));

  papers.sort(function (a, b) {
    return b.year - a.year;
  });

  let recPub = papers.slice(0, count)

  return (
    <>
      <StyledTitle>Recent Publications</StyledTitle>
      See also <Link to={"/pubs/"}>my full list of publications</Link>.
      {recPub.map((pub, index) => (
        <Citation key={index} pub={pub} people={people} />
      ))}
    </>
  );
};


const Home = ({ data, papers, people }) => {
  return (
    <>
      <Row>
        <Col sm={3}>
          <img
            src={process.env.PUBLIC_URL + "/cody_dunne.jpg"}
            width="100%"
            className="d-inline-block align-top portrait"
            alt="Data Vis logo"
            style={{ paddingBottom: "15px" }}
          />
          <PersonLinks people={people} personId={"Cody-Dunne"} />
        </Col>
        <Col sm={9}>
          <Row>
            <About />
          </Row>
          <Updates updates={data.updates} />
          <Row>
            <Link style={{ paddingTop: "20px" }} to={"/news/"}>
              News Archive
            </Link>
          </Row>
        </Col>
      </Row>

      <hr />
      <CenteredRow>
        <Carousels carousels={data.carousels} />
      </CenteredRow>
      <hr />
      <Row>
        <Col sm={12}>
          <RecentPublications
            papers={papers.publications}
            people={people}
            count={8}
          />
        </Col>
      </Row>
    </>
  );
};

export default Home;
